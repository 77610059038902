<template>
  <b-container class="pt-4">
    <b-row>
      <b-col sm="12" md="12" lg="6" class="mb-4">
        <div class="d-flex flex-row">
          <div class="w-100 text-white text-left pt-1 pt-sm-1">
            <div class="h3 text-uppercase" v-if="$i18n.locale === 'ru'">
              {{ tour_arr.title }}
            </div>
            <div class="h3 text-uppercase" v-else>
              {{ tour_arr.title_eng }}
            </div>
            <div class="text-uppercase font-weight-bold tour-date-font-size">
              <span v-if="$i18n.locale === 'ru'">{{ tour_arr.date }}</span>
              <span v-else>{{ tour_arr.date_eng }}</span>
              <span class="separator"> | </span>
              <span v-if="$i18n.locale === 'ru'">{{ tour_arr.weekday }}</span>
              <span v-else>{{ tour_arr.weekday_eng }}</span>
              <span class="separator"> | </span>
              <span>{{ tour_arr.time_start }}</span>
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3 d-none d-sm-block">
          <img src="../../assets/img/nba/basketball_logo.svg" alt="Logo" class="img-fluid"/>
        </div>
      </b-col>
      <b-col sm="12" md="12" lg="6" xl="4" class="mb-4 offset-xl-1">
        <div class="justify-content-center">
          <div class="h6 text-uppercase text-center" :class="dataSport.textClass">
            {{ $t('other.tour_participants') }}
          </div>
          <TournamentParticipants :tp="tour_arr.teams" :dataSport="dataSport"></TournamentParticipants>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" lg="6" class="mb-4">
        <div class="d-flex justify-content-center">
          <div class="h6 text-uppercase" :class="dataSport.textClass">
            {{ $t('other.group') }}
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <TourGroup :group="tour_arr.teams_group" :dataSport="dataSport"></TourGroup>
        </div>
      </b-col>
      <b-col sm="12" md="12" lg="6" xl="4" class="mb-4 offset-xl-1">
        <div class="justify-content-center">
          <div class="h6 text-uppercase text-center" :class="dataSport.textClass">
            {{ $t('other.tour_grid') }}
          </div>
          <TourGrid :sgrid="tour_arr.stages" :tgrid="tour_arr.teams" :dataSport="dataSport"></TourGrid>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="8" lg="6" class="offset-md-2 offset-lg-3 mb-5">
        <div class="d-flex justify-content-center title-games-block">
          <div class="h6 text-uppercase" :class="dataSport.textClass">
            {{ $t('other.tour_games') }}
          </div>
        </div>
        <TourStages :stages="tour_arr.stages" :dataSport="dataSport"></TourStages>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "ebasketball_tour",
  data() {
    return {
      tour_id: this.$route.params.id,
      tour_arr: [],
      tourTitle: '',
      dataSport:
        {
          textClass: "nba-text-color",
          kindOfSport: "ebasketball",
          sportId: 1
        }
    };
  },
  components: {
    TourStages: () => import("@/components/blocks/TourStages.vue"),
    TournamentParticipants: () => import("@/components/blocks/TournamentParticipants.vue"),
    TourGrid: () => import("@/components/blocks/TourGrid.vue"),
    TourGroup: () => import("@/components/blocks/TourGroup.vue")
  },
  mounted() {
    this.getApi(this.$url + "api/nba/tours/load/id/" + this.tour_id);
  },
  methods: {
    getApi(url) {
      this.$http.post(url).then(response => {
        this.tour_arr = response.data;
        this.tourTitle = response.data.title;
        this.tourDate = response.data.date + " | " + response.data.weekday;
        if (this.tour_arr.length === 0) {
          this.$router.push("/404");
        }
      });
    }
  },
  metaInfo () {
    return {
      title: this.tourTitle + " | " + this.tourDate + " | " + this.tour_arr.time_start,
      titleTemplate: 'H2H Liga - %s'
    }
  },
};
</script>

<style scoped>
.title-games-block {
  padding-left: 60px;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
<style>
.bl-none {
  border-left: none !important;
}
.bt-none {
  border-top: none !important;
}
.bb-none {
  border-bottom: none !important;
}
.table td, .table th {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.table-bordered td, .table-bordered th {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.table-bordered {
  border-top: none !important;
  border-left: none !important;
}
</style>